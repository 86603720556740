
.fadeInDown {
    -webkit-animation: fadeInDown 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInDown 1s; /* Firefox < 16 */
    -ms-animation: fadeInDown 1s; /* Internet Explorer */
    -o-animation: fadeInDown 1s; /* Opera < 12.1 */
    animation: fadeInDown 1s;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Internet Explorer */
@-ms-keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation: fadeInUp 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUp 1s; /* Firefox < 16 */
    -ms-animation: fadeInUp 1s; /* Internet Explorer */
    -o-animation: fadeInUp 1s; /* Opera < 12.1 */
    animation: fadeInUp 1s;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Firefox < 16 */
@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Internet Explorer */
@-ms-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
