/*!
 * Bootswatch v4.5.3
 * Homepage: https://bootswatch.com
 * Copyright 2012-2020 Thomas Park
 * Licensed under MIT
 * Based on Bootstrap
*//*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --grey: #d7d7d7;
  --comt-secondary: #60d0f0;
  --akt1-secondary: #F49D8E;
  --cyp2c9-secondary: #4ca8a2;
  --research-primary: #006ba6;
  --research-secondary: var(--research-primary);
  --genetics-primary: #85cb7a;
  --genetics-secondary: rgb(220,54,40);
  --genetics-tertiary: #C4B000;
  --survey-primary: #dc3280;
  --survey-secondary: #b81f84;
  --survey-tertiary: #f47da7;
}

.dashboard {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #333f48;
  text-align: left;
  background-color: #F7F9FA;

  a, *::before, *::after, img, span, input, button, .navbar, .fixedmenu, .tp-bullet, .owl-dot, .owl-prev, .owl-next {
    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
  }

  /* backround color */

  .gradient-bg1 {

    background: #FFFFFF;
    height: 70px;
    position: relative;
    z-index: 999;
    background: #ffffff;
    -webkit-animation-name: animationFade;
    -o-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  }


  /* text-color */

  .gradient-text1 {
    background: #006ba6;
    background: -moz-linear-gradient(left, #3264f5 2%, #7202bb 82%);
    background: -webkit-linear-gradient(left, #0A0D34 2%,#006ba6 82%);
    background: linear-gradient(to right, #0A0D34 2%,#006ba6 82%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3264f5', endColorstr='#7202bb',GradientType=1 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  body {
    font-family: Poppins, sans-serif;
  }

  section {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /* button */

  .btn {
    letter-spacing: 0px;
  }

  @media (min-width: 375px) {
    .btn {
      letter-spacing: .3px;
    }
  }

  .modal-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 576px) {
    .modal-header {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .modal-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 576px) {
    .modal-body {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .modal-footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 576px) {
    .modal-footer {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  /* Lobogene NavBar Code */

  .navbar {
    padding: 0.4rem 1.5rem;
  }

  .navbar-brand.d-sm-none.logo {
    margin-right: 0;
  }

  a.navbar-brand.logo img {
    max-height: 32px;
  }

  .nav-user-icon {
    font-size: 2rem;
  }

  .navbar-expand .navbar-nav .nav-link {
    color:#333f48;
    font-size: .875rem;
    letter-spacing: .5px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  @media (min-width: 576px) {
    .navbar-expand .navbar-nav .nav-link {
      font-size: 15px;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
  }

  .navbar.navbar-dark.halugen-nav {
    height: 75px;
  }

  .show > #user-dropdown.dropdown-menu {
    max-height: 500px;
    visibility: visible;
  }

  #user-dropdown.dropdown-menu {
    display: block;
    max-height: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    font-size: 1rem;
    top: 110%;
  }

  #user-dropdown.dropdown-menu .dropdown-item {
    padding: 0.5rem 1.5rem;
  }

  .dashboard-title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  @media (min-width: 576px) {
    .dashboard-title {
      font-size: 2.1875rem;
      margin-bottom: 2rem;
    }
  }

  /* Reports Card */

  .card.learn-card {
    margin-bottom: 20px;
    border-radius: .25rem;
    border: 0px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.08);
  }

  .card.reports-card {
    margin-bottom: 20px;
    border-radius: .25rem;
    border: 0px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.08);
  }

  .card-padding-mobile:nth-child(2n + 2) {
    padding-left: 7px;
  }

  @media (min-width: 576px) {
    .card-padding-mobile:nth-child(2n + 2) {
      padding-left: 0px;
    }
  }

  .card-padding-mobile:nth-child(2n + 1) {
    padding-right: 7px;
  }

  @media (min-width: 576px) {
    .card-padding-mobile:nth-child(2n + 1) {
      padding-right: 0px;
    }
  }

  @media (min-width: 576px) {
    .card.learn-card {
      margin-left: 0px;
      margin-right: 20px;
    }
  }

  @media (min-width: 576px) {
    .card.reports-card {
      margin-left: 0px;
      margin-right: 20px;
    }
  }

  .reports-card .btn {
    font-size: 12px;
    padding: .5rem .75rem;
  }

  @media (min-width: 360px) {
    .reports-card .btn {
      font-size: 14px;
      padding: .5rem 1rem;
    }
  }

  @media (min-width: 576px) {
    .reports-card .btn {
      font-size: 17px;
    }
  }

  .reports-card h5 {
    font-size: 14px;
    margin-bottom: 6px;
  }

  @media (min-width: 576px) {
    .reports-card h5 {
      font-size: 1.09375rem;
    }
  }

  .reports-card .card-body {
    padding: 0.6rem;
  }

  @media (min-width: 576px) {
    .reports-card .card-body {
      padding: 1.25rem;
    }
  }

  .reports-card .card-text {
    font-size: 12px;
  }

  @media (min-width: 576px) {
    .reports-card .card-text {
      font-size: 0.875rem;
    }
  }

  .reports-card .card-footer {
    padding-top: 0;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-bottom: 0.6rem;
    background: transparent;
    border: 0;
  }

  .learn-card .card-footer {
    padding-top: 0;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-bottom: 0.6rem;
    background: transparent;
    border: 0;
  }

  @media (min-width: 576px) {
    .learn-card .card-footer {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }

  @media (min-width: 576px) {
    .reports-card .card-footer {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }

  .learn-card img {
    padding: 0px 0px 0px 0px;
  }

  .reports-card img {
    padding: 20px 50px 10px 50px;
  }

  @media (min-width: 576px) {
    .reports-card img {
      padding: 20px 65px 20px 65px;
    }
  }

  .reports-card .card-footer i {
    font-size: 18px;
  }

  @media (min-width: 576px) {
    .reports-card .card-footer i {
      font-size: 24px;
    }
  }

  .reports-card .card-footer i.complete {
    color: var(--green);
  }

  .reports-card .card-footer i.pending {
    color: var(--secondary);
  }

  .reports-card .card-footer i.view {
    color: var(--primary);
  }

  .reports-card .card-footer i.incomplete {
    color: var(--warning);
  }

  .reports-card .card-footer a.icon-action {
    margin-top: 3px;
    text-decoration: underline;
    font-size: 9px;
  }

  .report-actions {
    text-align: center;
    align-self: center;
  }

  .summary-actions {
    text-align: right;
  }

  @media (min-width: 768px) {
    .report-actions {
      text-align: right;
    }
  }

  .list-group .list-group-item {
    color: #6d6d6d;
    background: none;
  }

  .list-group.research-papers-list a {
    text-decoration: underline;
    color: var(--research-secondary);
    font-weight: 600;
  }

  @media (min-width: 576px) {
    section {
      margin-top: 2rem;
      margin-bottom: 5rem;
    }
  }

  .research-papers-list.list-group .list-group-item {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }

  .profile-icon {
    font-size: 50px;
    margin-right: 15px;
  }

  .checkmark-icon {
    font-size: 30px;
    margin-right: 15px;
  }

  .card.overview-card {
    border-radius: .25rem;
    border: 0px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.08);
    margin-bottom: 20px;
  }

  .card.overview-card .card-header {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
  }

  .card.overview-card .overview-card-title {
    padding: 2em 1.5em 2em 1.5em;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
  }

  .card.overview-card .overview-offers-card-title {
    padding: 1.5em 1.5em 1em 1.5em;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
  }

  .card.overview-card .overview-card-title h2 {
    font-size: 1.5rem;
  }

  @media (min-width: 576px) {
    .card.overview-card .overview-card-title h2 {
      font-size: 1.75rem;
    }
  }

  .card.overview-card .card-body .btn {
    margin-top: 10px;
  }

  .nav-link:hover {
    text-decoration:none;
    color:#333F48;
    border-color: #333F48;
    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
  }

  .custom-control.custom-radio {
    min-height: 1.5rem;
  }

  .custom-control.custom-checkbox {
    min-height: 1.5rem;
  }

  .nav.nav-pills.nav-justified {
  }

  .table.scoring-table thead th {
    border: none;
    font-weight: 600;
  }

  .table.scoring-table td, .table th {
    border-top: none;
    padding: 0.2rem;
    min-width: 45px;
  }

  #summary-scoring-table.table thead th {
    border: none;
  }

  #summary-scoring-table.table td, .table th {
    border-top: none;
    padding: 0.1rem;
    min-width: 50px;
  }

  .survey-summary-col-margin:nth-child(1) {
    margin-right: 0px;
  }

  @media (min-width: 992px) {
    .survey-summary-col-margin:nth-child(1) {
      margin-right: 30px;
    }
  }

  .family-history-check {
    font-size: 12px;
    color: var(--survey-primary);
    margin-right: 4px;
  }

  /* --------------------------Research Nav Pills Navigation Bar--------------------------- */

  .nav.nav-pills.research-color {
    background: rgba(0,107,166,0.07);
  }

  .nav-pills.research-color .nav-link.active, .nav-pills.research-color .show > .nav-link {
    color: #ffffff;
    background-color: var(--research-primary);
  ;
  }

  .research-color .nav-link:hover {
    background-color: rgba(0, 107, 166, 0.2);
    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
  }

  .nav-pills.research-color a {
    color: var(--research-secondary);
  }

  .nav-pills.research-color a:hover {
    color: var(--research-secondary);
  }

  /* -----------------------Swab Instructions Card-------------------- */

  .swab-instructions-card .swab-instructions-number {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 5px;
    border: solid 2px;
    border-color: #c4b000;
    margin-right: 15px;
    margin-bottom: 0;
    line-height: 1rem;
  }

  .swab-instructions-card hr {
    margin-top: 1.4rem;
    margin-bottom: 2.5rem;
    border: 0;
  }

  .swab-instructions-card h4 {
    font-size: 1.1rem;
  }

  @media (min-width: 768px) {
    .swab-instructions-card h4 {
      font-size: 1.3rem;
    }
  }

  .tutorial-video-container iframe {
    width: 100%;
    min-height: 200px;
  }

  @media (min-width: 576px) {
    .tutorial-video-container iframe {
      min-height: 300px;
    }
  }

  @media (min-width: 768px) {
    .tutorial-video-container iframe {
      min-height: 405px;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #006BA6;
    border-color: #006BA6;
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem #f7f9fa;
  }

  .btn-primary:focus {
    box-shadow: 0 0 5px rgba(0,107,166,0.88);
  }

  .btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: rgba(0,107,166,0.88);
    border-color: #006ba6;
    box-shadow: 0 0 0 .2rem #f7f9fa;
  }

  a {
    color: #006ba6;
    text-decoration: none;
    background-color: transparent;
  }

  .btn-primary {
    color: #fff;
    background-color: #006ba6;
    border-color: #006ba6;
    border-radius:12px;
    line-height: 25px;
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #333f48;
    border-color: #333f48;

  }

  .btn-secondary {
    color: #006ba6;
    background-color: #fff;
    border-color: #006ba6;
    border-radius:12px;
    line-height: 25px;
  }

  .btn-secondary:hover {
    color: #fff;
    background-color: #333f48;
    border-color: #333f48;

  }

  a:hover {
    text-decoration: none;
    color:#333f48;
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #FFFFFF;
    background-color: #006ba6;
  }



  .btn-link:hover {
    color: #006ba6!important;
    text-decoration: underline;
  }

  .btn-link {
    font-weight: 400;
    color: #006ba6;
    text-decoration: none;
  }

  .border-primary {
    border-color: #006ba6!important;
  }

  .col-horizontalscroll {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    width: 4000px;
    height: auto;
    max-height: 100vh;
    overflow-y: hidden;
  }

  .overview-card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 0rem;
  }

  .text-primary {
    color: #006ba6!important;
  }

  .media.summary-list {
    line-height: 0px;
    padding: .5rem 0rem 0.5rem 0rem;
  }

  .table-responsive {
    display: inline-block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .metabolism-table-header {
    background-color: var(--genetics-tertiary);
    color: white;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--research-primary)!important;
  }

  .dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: var(--research-secondary);
  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: var(--research-primary);
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color:#F7F9FA;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link:hover {
    border-bottom: 1px solid var(--research-primary);
    border-top: none;
    border-left: none;
    border-right: none;

  }

  .carousel-indicator.active {
  }

  .carousel-indicators.active {
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    list-style: none;
  }

  .carousel-indicators {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
    font-color: var(--research-primary);
  }

  .carousel-indicators .active {
    opacity: 1;
    background-color: var(--research-primary);
  }

  .carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #006ba6;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
  }

  .carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #006ba6;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }

  .lobo-report-procon {
    border: 1px solid #2c82be;
    border-top-color: rgb(44, 130, 190);
    border-radius: 6px;
  }

  .report.thc .lobo-report-procon {
    border: 1px solid #2c82be;
    border-top-color: rgb(44, 130, 190);
    border-radius: 6px;
  }

  .report.thc .lobo-report-procon {
    border: 1px solid #2c82be;
    border-top-color: rgb(44, 130, 190);
    border-radius: 6px;
  }

  .lobo-report-procon > :first-child {
    display: flex;
    align-items: center;
    border-top-left-radius: inherit;
  }

  @media (max-width: 769px) {
    .lobo-genotype-compare {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 600px) {
    .lobo-genotype-compare-cbd {
      flex-direction: column;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .bg-secondary {
    background-color: rgba(76,168,162,0.5333333333333333)!important;
  }

  .btn-light{
     background-color: #F7F9FA;
  }
  .btn-light:hover{
    border-color: #333F48;
    color: #333f48;
  }

}
