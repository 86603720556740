

@import "./assets/css/font-awesome.min.css";
@import "./assets/css/style.scss";


.text-black {
  color: #000000 !important;
}

.fadeInDown_2 {
  -webkit-animation: fadeInDown 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInDown 2s; /* Firefox < 16 */
  -ms-animation: fadeInDown 2s; /* Internet Explorer */
  -o-animation: fadeInDown 2s; /* Opera < 12.1 */
  animation: fadeInDown 2s;
}

.fadeInDown {
  -webkit-animation: fadeInDown 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInDown 1s; /* Firefox < 16 */
  -ms-animation: fadeInDown 1s; /* Internet Explorer */
  -o-animation: fadeInDown 1s; /* Opera < 12.1 */
  animation: fadeInDown 1s;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation: fadeInUp 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInUp 1s; /* Firefox < 16 */
  -ms-animation: fadeInUp 1s; /* Internet Explorer */
  -o-animation: fadeInUp 1s; /* Opera < 12.1 */
  animation: fadeInUp 1s;
}

.fadeInUp_2 {
  -webkit-animation: fadeInUp 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInUp 2s; /* Firefox < 16 */
  -ms-animation: fadeInUp 2s; /* Internet Explorer */
  -o-animation: fadeInUp 2s; /* Opera < 12.1 */
  animation: fadeInUp 2s;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Firefox < 16 */
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInRight {
  -webkit-animation: fadeInRight 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInRight 1s; /* Firefox < 16 */
  -ms-animation: fadeInRight 1s; /* Internet Explorer */
  -o-animation: fadeInRight 1s; /* Opera < 12.1 */
  animation: fadeInRight 1s;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


/* Firefox < 16 */
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.fadeInLeft {
  -webkit-animation: fadeInLeft 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInLeft 1s; /* Firefox < 16 */
  -ms-animation: fadeInLeft 1s; /* Internet Explorer */
  -o-animation: fadeInLeft 1s; /* Opera < 12.1 */
  animation: fadeInLeft 1s;
}

.fadeInLeft_2 {
  -webkit-animation: fadeInLeft 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInLeft 2s; /* Firefox < 16 */
  -ms-animation: fadeInLeft 2s; /* Internet Explorer */
  -o-animation: fadeInLeft 2s; /* Opera < 12.1 */
  animation: fadeInLeft 2s;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


/* Firefox < 16 */
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInCustom {
  animation: fadeInCustom ease 2s;
  -webkit-animation: fadeInCustom ease 2s;
  -moz-animation: fadeInCustom ease 2s;
  -o-animation: fadeInCustom ease 2s;
  -ms-animation: fadeInCustom ease 2s;
}

@keyframes fadeInCustom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Firefox < 16 */
@-moz-keyframes fadeInCustom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInCustom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInCustom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInCustom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.zoomInCustom {
  animation: zoominCustom 1.5s forwards;
  -webkit-animation: zoominCustom 1.5s forwards;
  -moz-animation: zoominCustom 1.5s forwards;
  -o-animation: zoominCustom 1.5s forwards;
  -ms-animation: zoominCustom 1.5s forwards;
}

@keyframes zoominCustom {
  0% {
    transform: scale(1.4,1.4);
  }
  100% {
    transform: scale(1,1);
  }
}


/* Firefox < 16 */
@-moz-keyframes zoominCustom {
  0% {
    transform: scale(1.4,1.4);
  }
  100% {
    transform: scale(1,1);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes zoominCustom {
  0% {
    transform: scale(1.4,1.4);
  }
  100% {
    transform: scale(1,1);
  }
}

/* Internet Explorer */
@-ms-keyframes zoominCustom {
  0% {
    transform: scale(1.4,1.4);
  }
  100% {
    transform: scale(1,1);
  }
}

/* Opera < 12.1 */
@-o-keyframes zoominCustom {
  0% {
    transform: scale(1.4,1.4);
  }
  100% {
    transform: scale(1,1);
  }
}

.fadeInUpCard {
  -webkit-animation: fadeInUpCard 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInUpCard 1s; /* Firefox < 16 */
  -ms-animation: fadeInUpCard 1s; /* Internet Explorer */
  -o-animation: fadeInUpCard 1s; /* Opera < 12.1 */
  animation: fadeInUpCard 1s;
}

@keyframes fadeInUpCard {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Firefox < 16 */
@-moz-keyframes fadeInUpCard {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInUpCard {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Internet Explorer */
@-ms-keyframes fadeInUpCard {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeInUpCard {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.fade-custom {
  -webkit-animation: fade 0.8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fade 0.8s; /* Firefox < 16 */
  -ms-animation: fade 0.8s; /* Internet Explorer */
  -o-animation: fade 0.8s; /* Opera < 12.1 */
  animation: fade 0.8s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Firefox < 16 */
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 8px;
  border: 4px solid #603691;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #603691 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
